"use client";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";
import { PlaceCard } from "./PlaceCard";
import { dangerouslySetInnerHTML } from "@lib/utils";
import { useTranslation } from "@hooks/useTranslation";

function TopPlacesSlider({
  cities,
}: {
  cities: {
    id: number;
    name: string;
    imgUrl: string;
  }[];
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center w-full mb-20">
      <h2
        className="mt-4 md:mt-3 mb-1 md:mb-2 text-2xl font-bold tracking-wide text-center md:text-2xl text-white"
        dangerouslySetInnerHTML={dangerouslySetInnerHTML(t("home.top_places"))}
      />
      <div className=" flex items-center justify-center w-[2000px] overflow-x-hidden mt-4">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          initialSlide={1}
          coverflowEffect={{
            rotate: 30,
            stretch: 50,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={{
            enabled: true,
            bulletActiveClass: "!bg-primary-color !w-3",
            bulletClass:
              "mx-0.5 mt-12 inline-block w-2 h-2 bg-gray-300/40 rounded-full",
          }}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper h-60"
        >
          {cities.map((city) => {
            return (
              <SwiperSlide
                key={city.id}
                className="relative flex flex-col p-2 cursor-pointer !w-52 !h-52 mb-12"
              >
                <PlaceCard
                  cityId={city.id}
                  title={city.name}
                  imgUrl={city.imgUrl}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default TopPlacesSlider;
