export const SECTIONS = ["places-to-visit", "experiences", "hotels"] as const;

export type Section = (typeof SECTIONS)[number];

export const API_URL = "https://lb-cdn-prod.beder.app/api/v1";

export const AUTH_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkcWRkZDIzMjMzIiwibmFtZSI6IkJlZGVyJCQkIiwiaWF0IjoyNjM1MTI2MzJ9.zvXrAUGolkG1wwcNYKyh3-Piphe5VfKjbSeWKNWX2ZI";

export const DEFAULT_AVATAR = "https://storage.googleapis.com/theapp.res.beder.app/dev/resources/avatar.jpg";

export const routes = {
  home: "/",
  placesToVisit: "plc",
  experiences: "exp",
  hotels: "htl",
};