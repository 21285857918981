import React from "react";
import Link from "next/link";
import Image from "next/image";

import { routes } from "@lib/constants";

interface PlaceCardProps {
  cityId: number;
  title: string;
  imgUrl: string;
}

export const PlaceCard = ({ cityId, title, imgUrl }: PlaceCardProps) => {
  return (
    <Link
      id={`btnHomeTopPlaces${cityId}`}
      href={`/spotprofile/${cityId}/${routes.placesToVisit}`}
    >
      <Image
        src={imgUrl}
        alt={`${title} image`}
        width={200}
        height={200}
        className="absolute top-0 left-0 border-2 border-gray-200/40 rounded-3xl -z-10 aspect-square"
      />
      <div className="absolute bottom-0 left-0 flex items-center justify-center w-11/12 p-2 m-2 font-bold text-white bg-gray-700 lg:text-lg bg-opacity-60 h-14 rounded-b-3xl">
        {title}
      </div>
    </Link>
  );
};
