import { MainLayout } from "@layouts";
import { Divider } from "@components/shared";
import Image from "next/image";
import type { GetStaticPropsContext, InferGetStaticPropsType } from "next";

import { config } from "@shared/config";
import { getSpotActivities, getSpotInfo, getSpotPlacesToVisit, getSpotTopExperiences, getSpotTopMustSeeExperiences, getTopPlaces } from "@lib/api";
import { dangerouslySetInnerHTML } from "@lib/utils";
import TopPlacesSlider from "@components/home/top-places-slider";
import { useTranslation } from "@hooks/useTranslation";
import SearchBar from "@components/home/search-bar";

const Home = ({ cities, search, searchAct }: InferGetStaticPropsType<typeof getStaticProps>) => {
  const hero_section_bg = config?.HERO_SECTION_BG;

  const { t } = useTranslation();

  return (
    <MainLayout hideGet={true}>
      <div className="relative flex flex-col items-center pb-8 pt-16 md:pt-24 border-b border-gray-500">
        <div className="absolute -top-24 -z-[1] w-full h-full">
          <Image
            src={hero_section_bg}
            alt={"Hero Image"}
            className="object-cover"
            quality={80}
            fill
            sizes="100vw"
            loading="eager"
          />
        </div>

        <div className="flex flex-col items-left w-full h-4/5 mt-2 ">
          <h2
            className="mb-0 sm:mb-2 text-md sm:text-2xl font-bold text-left mt-5  mx-[7%] md:mx-[30%]"
            dangerouslySetInnerHTML={dangerouslySetInnerHTML(
              t("home.headings.title")
            )}
          />
          <h1 
            className="mx-2 text-4xl tracking-tighter sm:text-7xl text-black font-normal mb-16 text-left mx-[7%] md:mx-[30%] "
            dangerouslySetInnerHTML={dangerouslySetInnerHTML(
              t("home.headings.subtitle1")
            )}
          />
          <SearchBar searchCache={search} searchActCache={searchAct} />
          <TopPlacesSlider cities={cities} />
        </div>
        <Divider />
      </div>
    </MainLayout>
  );
};

export default Home;

export async function getStaticProps(ctx: GetStaticPropsContext) {
  const locale = ctx.locale || ctx.defaultLocale;
  const {
    data: [topPlaces],
  } = await getTopPlaces();


 

  let arraySearch: any[] = [];
  let arraySearchAct: any[] = [];

  console.log('SEARCH CACHE: top places');

 //top places
 for (let i = 0; i < topPlaces.length; i++) {
    
  const {
    data: [[spotInfo]],
  } = await getSpotInfo(topPlaces[i].city_id.toString());

  console.log('getSpotInfoCountry',topPlaces[i].city_id.toString(),spotInfo)

      arraySearch.push({
        topPlaces: true,
        cityId: topPlaces[i].city_id,
        cityName:  locale === "es"
                  ? topPlaces[i].city.spanish_name
                  : locale === "fr"
                  ? topPlaces[i].city.french_name
                  : topPlaces[i].city.english_name,
        countryName:  locale === "es"
        ? spotInfo.country.spanish_name
        : locale === "fr"
        ? (spotInfo.country.french_name == null? spotInfo.country.english_name : spotInfo.country.french_name)
        : spotInfo.country.english_name,
      });
    
 }

 
//Additional Places to Search

console.log('SEARCH CACHE: Places to Search');

    const arraySearchCities = process.env.SEARCH_CITIES == undefined ? config.SEARCH_CITIES.split(',') : process.env.SEARCH_CITIES.split(',');
    const arrayTopCities = config.TOP_CITIES.split(',');

    for(let i = 0; i < arraySearchCities.length; i++)
    {
      const {
        data: [[spotInfo]],
      } = await getSpotInfo(arraySearchCities[i]);

      

      console.log('SEARCH CACHE: Places to Search - ', arraySearchCities[i]);

      arraySearch.push({
        topPlaces: false,
        cityId: arraySearchCities[i],
        cityName:  locale === "es"
                  ? spotInfo.spanish_name
                  : locale === "fr"
                  ? spotInfo.french_name
                  : spotInfo.english_name,
        countryName:  locale === "es"
        ? spotInfo.country.spanish_name
        : locale === "fr"
        ? (spotInfo.country.french_name == null? spotInfo.country.english_name : spotInfo.country.french_name)
        : spotInfo.country.english_name,
      })
    }

    //Activities Experiences
    console.log('SEARCH CACHE: Activities Experiences ');

    for(let i = 0; i < arrayTopCities.length; i++)
    {
      const {
        data: [experiences],
      } = await getSpotTopExperiences(arrayTopCities[i]);

      console.log('SEARCH CACHE: Activities Experiences - ', arrayTopCities[i]);

      const {
        data: [[spotInfo]],
      } = await getSpotInfo(arrayTopCities[i]);

      for(let j = 0; j < experiences.length; j++)
      {
        if(experiences[j]?.activity != undefined)
          for(let l = 0; l < experiences[j].activity.length; l++)
          {
            const activityName = locale === "es"
            ? experiences[j].activity[l].name_es
            : locale === "fr"
            ? experiences[j].activity[l].name_fr
            : experiences[j].activity[l].name_en;

            if(activityName != null && activityName != '')
            arraySearchAct.push({
              topPlaces: true,
              exp: true,
              activityName: activityName,
              cityId: arrayTopCities[i],
              experienceId: experiences[j].spot_top_experience_id,
              cityName: locale === "es"
              ? spotInfo.spanish_name
              : locale === "fr"
              ? spotInfo.french_name
              : spotInfo.english_name,
              countryName: locale === "es"
              ? spotInfo.country.spanish_name
              : locale === "fr"
              ? (spotInfo.country.french_name == null? spotInfo.country.english_name : spotInfo.country.french_name)
              : spotInfo.country.english_name,
            })
            console.log('arraySearchAct ', arraySearchAct.length)
          }

      }
    }

    //Activities Aditional Experiences
    console.log('SEARCH CACHE: Activities Aditional Experiences ');

    



    for(let i = 0; i < arraySearchCities.length; i++)
    {
        const {
          data: [experiences],
        } = await getSpotActivities(arraySearchCities[i]);

        console.log('SEARCH CACHE: Activities Aditional Experiences - ', arraySearchCities[i]);

        const {
          data: [[spotInfo]],
        } = await getSpotInfo(arraySearchCities[i]);

        for(let j = 0; j < experiences.length; j++)
        {
          for(let l = 0; l < experiences[j]?.activity.length; l++)
          {
            const activityName = locale === "es"
            ? experiences[j].activity[l].name_es
            : locale === "fr"
            ? experiences[j].activity[l].name_fr
            : experiences[j].activity[l].name_en;

            console.log('activityName',activityName)

            if(activityName != null && activityName != '')
              arraySearchAct.push({
                topPlaces: false,
                exp: true,
                activityName: activityName,
                cityId: arraySearchCities[i],
                experienceId: experiences[j].spot_id,
                cityName: locale === "es"
                ? spotInfo.spanish_name
                : locale === "fr"
                ? spotInfo.french_name
                : spotInfo.english_name,
                countryName: locale === "es"
                ? spotInfo.country.spanish_name
                : locale === "fr"
                ? (spotInfo.country.french_name == null? spotInfo.country.english_name : spotInfo.country.french_name)
                : spotInfo.country.english_name,
              })
          }

        }
     }

     //Activities Places
     console.log('SEARCH CACHE: Activities Places ');

     for(let i = 0; i < arrayTopCities.length; i++)
     {
       const {
         data: [experiences],
       } = await getSpotTopMustSeeExperiences(arrayTopCities[i]);
 
       console.log('SEARCH CACHE: Activities Places - ', arrayTopCities[i]);

       const {
        data: [[spotInfo]],
      } = await getSpotInfo(arrayTopCities[i]);
 
       for(let j = 0; j < experiences.length; j++)
       {
         if(experiences[j]?.activity != undefined)
           for(let l = 0; l < experiences[j].activity.length; l++)
           {
 
            const activityName = locale === "es"
            ? experiences[j].activity[l].name_es
            : locale === "fr"
            ? experiences[j].activity[l].name_fr
            : experiences[j].activity[l].name_en;

            console.log('activityName',activityName)

            if(activityName != null && activityName != '')
             arraySearchAct.push({
               topPlaces: true,
               exp: false,
               activityName: activityName,
               cityId: arrayTopCities[i],
               experienceId: experiences[j].spot_id,
               cityName: locale === "es"
               ? spotInfo.spanish_name
               : locale === "fr"
               ? spotInfo.french_name
               : spotInfo.english_name,
               countryName: locale === "es"
               ? spotInfo.country.spanish_name
               : locale === "fr"
               ? (spotInfo.country.french_name == null? spotInfo.country.english_name : spotInfo.country.french_name)
               : spotInfo.country.english_name,
             })
           }
 
       }
     }



    //Activities Aditional Places
    console.log('SEARCH CACHE: Activities Aditional Places ');

    for(let i = 0; i < arraySearchCities.length; i++)
    {

        const {
          data: [experiences],
        } =await getSpotPlacesToVisit(arraySearchCities[i]);

        console.log('SEARCH CACHE: Activities Aditional Places -',arraySearchCities[i]);

        const {
          data: [[spotInfo]],
        } = await getSpotInfo(arraySearchCities[i]);

        for(let j = 0; j < experiences.length; j++)
        {
          for(let l = 0; l < experiences[j]?.activity.length; l++)
          {
            const activityName = locale === "es"
            ? experiences[j].activity[l].name_es
            : locale === "fr"
            ? experiences[j].activity[l].name_fr
            : experiences[j].activity[l].name_en;

            console.log('activityName',activityName)

            if(activityName != null && activityName != '')
              arraySearchAct.push({
                topPlaces: false,
                exp: false,
                activityName:activityName,
                cityId: arraySearchCities[i],
                experienceId: experiences[j].spot_id,
                cityName: locale === "es"
                ? spotInfo.spanish_name
                : locale === "fr"
                ? spotInfo.french_name
                : spotInfo.english_name,
                countryName: locale === "es"
                ? spotInfo.country.spanish_name
                : locale === "fr"
                ? (spotInfo.country.french_name == null? spotInfo.country.english_name : spotInfo.country.french_name)
                : spotInfo.country.english_name,
              })
          }

        }
     }

     console.log('arraySearch SIZE',arraySearchAct.length)


     


  return {
    props: {
      cities: topPlaces.map((el) => ({
        id: el.city_id,
        name:
          locale === "es"
            ? el.city.spanish_name
            : locale === "fr"
            ? el.city.french_name
            : el.city.english_name,
        imgUrl: el.city.discover_image_1,
      })),
      search: arraySearch,
      searchAct: arraySearchAct
    },
  };
}
